<template>
  <div>
    <el-dialog :show-close="false" :visible="dialogVisible" :width="width">
      <template slot="title">
        <span style="font-size: 18px">商品{{ enterText }}</span>
      </template>
      <div class="flex-row">
        <span class="label">{{ enterText }}类型</span>
        <span>
          <el-radio-group v-model="intoForm.inventoryType">
            <el-radio
              v-for="item in intoTypeList"
              :key="item.name"
              :label="item.id"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </span>
      </div>
      <div class="flex-row" style="margin-top: 20px; align-items: flex-start">
        <span class="label">备注</span>
        <span>
          <el-input
            v-model="intoForm.remark"
            maxlength="30"
            placeholder="请输入备注"
            show-word-limit
            style="width: 670px"
            type="textarea"
          />
        </span>
      </div>
      <div class="flex-row" style="margin-top: 20px">
        <span class="label">{{ enterText }}商品</span>
        <span>
          <el-button size="medium" style="margin: 0" @click="addCommodity">
            添加商品
          </el-button>
        </span>
      </div>
      <div class="table-box">
        <Table
          :button-type="true"
          :button-width="100"
          :header-style="headerStyle"
          :height="''"
          :orientation="'center'"
          :page="true"
          :serial-number="true"
          :table-data="tableData"
          :title-list="titleListData"
          @operationClick="operationClick"
        >
          <template #num="{ data }">
            <el-input-number
              :key="data.goodsId"
              v-model="data.num"
              :controls="false"
              :min="1"
              size="small"
              style="width: 100px"
            />
          </template>
        </Table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="closeDialog">取 消</el-button>
        <el-button
          :loading="buttonLoading"
          size="medium"
          type="primary"
          @click="enter"
        >
          确定{{ enterText }}
        </el-button>
      </div>
    </el-dialog>
    <SelectCommodity
      :dialog-visible="selectDialogVisible"
      :table-list="tableList"
      @close="closeSelectCommodity"
      @enter="enterSelectCommodity"
    />
  </div>
</template>

<script>
import Table from "@/components/table2";
import SelectCommodity from "../selectCommodity";
import { setGoodsIntoAndOut } from "@/api/commodity";

export default {
  components: {
    Table,
    SelectCommodity,
  },
  props: {
    width: {
      type: String,
      default: "800px",
    },
    enterText: {
      type: String,
      default: "入库",
    },
    intoTypeList: {
      type: Array,
      required: true,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    headerStyle: {
      type: Object,
      default: () => {},
    },
    titleListData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      intoForm: {
        inventoryType: this.enterText == "入库" ? 1 : 5,
        recordType: this.enterText == "入库" ? 1 : 2,
        operateType: 2,
      },
      tableData: [],
      selectDialogVisible: false,
      tableList: [],
      buttonLoading: false,
    };
  },
  watch: {
    dialogVisible(value) {
      if (value == false) {
        this.tableData = [];
        this.tableList = [];
        this.intoForm = {
          inventoryType: this.enterText == "入库" ? 1 : 5,
          recordType: this.enterText == "入库" ? 1 : 2,
          operateType: 2,
        };
      }
    },
    tableData: {
      handler(list) {
        this.tableList = [];
        list.forEach((item) => {
          this.tableList.push({
            goodsId: item.goodsId,
            num: item.num,
          });
        });
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    enter() {
      if (!this.intoForm.inventoryType) {
        this.$message.error("请选择入库类型！");
        return;
      }
      if (!this.tableData || this.tableData.length == 0) {
        this.$message.error("请添加商品！");
        return;
      }
      console.log(this.tableData);
      let bool = this.tableData.some((item) => !item.num);
      if (bool) {
        this.$message.error("请输入商品数量！");
        return;
      }
      this.intoForm.recordDetailList = this.tableData.map((item) => {
        return {
          goodsId: item.goodsId,
          num: item.num,
        };
      });
      this.buttonLoading = true;
      setGoodsIntoAndOut({
        ...this.intoForm,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            this.$message.success(`商品${this.enterText}成功！`);
            this.$emit("enter");
          } else {
            this.$message.error(data.msg);
          }
          this.buttonLoading = false;
        })
        .catch(() => {
          this.buttonLoading = false;
        });
    },
    addCommodity() {
      this.selectDialogVisible = true;
    },
    operationClick({ row, code }) {
      if (code == "delete") {
        this.$confirm("是否删除当前商品信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.tableList = [];
            this.tableData = this.tableData.filter((item) => {
              if (item.goodsId != row.goodsId) {
                this.tableList.push({
                  goodsId: item.goodsId,
                  num: item.num,
                });
                return item;
              }
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    closeSelectCommodity(value) {
      this.selectDialogVisible = value;
    },
    enterSelectCommodity(data) {
      this.tableList = [];
      this.tableData = data.map((item) => {
        this.tableList.push({
          goodsId: item.goodsId,
          num: item.num,
        });
        item.buttonList = [
          {
            name: "删除",
            size: "medium",
            type: "text",
            code: "delete",
            width: "100",
          },
        ];
        return item;
      });
      this.selectDialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.flex-row {
  display: flex;
  align-items: center;
}
.label {
  margin-right: 20px;
  color: #101010;
  width: 60px;
  text-align: right;
}
.table-box {
  margin-top: 10px;
}
.dialog-footer {
  text-align: center;
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
::v-deep .el-textarea__inner {
  height: 120px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
</style>
