<template>
  <div v-loading="loading" class="title">
    <el-table
      ref="elTable"
      :border="borderBool"
      :cell-style="setCellStyle"
      :data="tableData"
      :header-cell-style="headerStyle"
      :height="height ? height : null"
      :key="keyValue"
      :fit="fit"
      row-key="id"
      style="width: 100%;"
      :tree-props="treeProps"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <!-- 勾选 -->
      <el-table-column v-if="select" fixed="left" type="selection" width="55" />
      <!-- 序号 -->
      <el-table-column
        v-if="serialNumber"
        :align="orientation"
        label="序号"
        property="index"
        type="index"
        width="50"
      >
        <template slot-scope="scope">
          <span>{{ (currentValue - 1) * 10 + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- 具体内容 -->
      <el-table-column
        v-for="item in titleList"
        :key="item.prop"
        :align="orientation"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width"
        :show-overflow-tooltip="tooltipShow"
      >
        <template slot-scope="scope">
          <span
            v-if="item.buttonBool"
            :size="item.size"
            style="color: #3370FF; cursor: pointer"
            :type="item.type"
            @click="textClick(scope.row, item.prop)"
          >
            {{ scope.row[item.prop] }}
          </span>
          <span v-else-if="item.type == 'slot'">
            <slot :data="scope.row" :name="item.prop"></slot>
          </span>
          <span v-else-if="item.prop == 'markDictId'" class="sign">
            <el-select
              ref="Select"
              v-model.trim="scope.row[item.prop]"
              filterable
              :id="`searchSelect${scope.row.id}` + scope.$index"
              allow-create
              default-first-option
              @change="buttonClick(scope, item.prop)"
              @input.native="blurOptions(scope)"
              placeholder="请选择标记"
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.itemValue"
                :label="item.itemText"
                :value="item.itemValue"
              >
              </el-option>
            </el-select>
            <span>{{ scope.row.markTime ? scope.row.markTime : "" }}</span>
          </span>
          <span v-else-if="item.prop != 'image'">
            <span v-if="item.prop == 'outClothesNum'">
              <el-tag size="mini" type="success" v-if="scope.row.ifRewash">
                返
              </el-tag>
              <el-tag
                size="mini"
                type="info"
                v-if="scope.row.clothesStatus === '已退洗'"
              >
                退
              </el-tag>
              <el-tag
                size="mini"
                type="success"
                v-if="scope.row.accessoryNumber"
                >附</el-tag
              >
              {{ scope.row.outClothesNum }}
            </span>
            <span v-else>
              {{
                scope.row[item.prop] || scope.row[item.prop] == 0
                  ? scope.row[item.prop]
                  : "--"
              }}
            </span>
          </span>
          <el-image
            v-else
            fit="contain"
            :preview-src-list="scope.row.image"
            :src="scope.row.imageSrc"
            class="images"
          >
            <div slot="error" class="image-slot">
              暂无图片
            </div>
          </el-image>
          <span
            v-show="
              item.prop == 'status' && item.bool && scope.row.viewStatus == 0
            "
            class="text"
          ></span>
          <!-- 异常标识 可以优化-->
          <span v-if="item.prop == 'storeBelongAnomalyFlag'">
            <el-tag
              size="mini"
              type="success"
              v-if="scope.row.storeBelongAnomalyFlag == 0"
            >
              否
            </el-tag>
            <el-tag size="mini" type="danger" v-else>
              是
            </el-tag>
          </span>
          <span v-if="item.prop == 'clothesStatusAnomalyFlag'">
            <el-tag
              size="mini"
              type="success"
              v-if="scope.row.clothesStatusAnomalyFlag == 0"
              >否</el-tag
            >
            <el-tag size="mini" type="danger" v-else>
              是
            </el-tag>
          </span>
        </template>
      </el-table-column>
      <!-- 操作按钮 -->
      <el-table-column
        v-if="buttonType"
        :align="orientation"
        fixed="right"
        :label="label"
        :width="buttonWidth"
      >
        <template slot-scope="scope">
          <span v-for="item in scope.row.buttonList" :key="item.name">
            <el-button
              :size="item.size"
              :style="{
                marginRight: '5px',
                color: item.color ? item.color : null,
              }"
              :type="item.type"
              @click="buttonClick(scope, item.code)"
            >
              {{ item.name }}
            </el-button>
          </span>
        </template>
      </el-table-column>
      <div slot="empty">
        <el-empty description="暂无数据" :image-size="100" />
      </div>
    </el-table>
    <div v-if="!page" class="pageBox">
      <el-pagination
        :background="true"
        :current-page="currentValue"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    titleList: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    borderBool: {
      type: Boolean,
      default: false,
    },
    treeProps: {
      type: Object,
      default: () => ({}),
    },
    headerStyle: {
      type: Object,
      default: () => ({
        background: "#FFF",
      }),
    },
    height: {
      type: String,
      default: "100%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentValue: {
      type: Number,
      default: 1,
    },
    orientation: {
      type: String,
      default: "center",
    },
    serialNumber: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    tooltipShow: {
      type: Boolean,
      default: true,
    },
    keyValue: {
      type: Number,
      default: 1,
    },
    buttonWidth: {
      type: Number,
    },
    fit: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "操作",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(value) {
      this.$emit("handleCurrentChange", value);
    },
    handleSelectionChange(data) {
      this.$emit("selectChange", data);
    },
    // 点击操作按钮出发
    buttonClick(data, code) {
      this.$emit("operationClick", { row: data.row, code: code });
    },
    textClick(data, code) {
      this.$emit("textClick", { data: data, code: code });
    },
    blurOptions(scope) {
      var input = document.querySelector(`#searchSelect${scope.row.id}`);

      if (input) {
        input.setAttribute("maxLength", 5);
        input.value = input.value.replace(/(^\s*)|(\s*$)/g, "");
        if (input.value.length == 5) {
          this.$message.error("只能输入5个字符");
        }
      }
    },
    setCellStyle({ column, row }) {
      if (column.label == "对账结果") {
        if (row.billResultName == "未确认") {
          return "color: rgba(155, 155, 155, 100)";
        } else if (row.billResultName == "有差异") {
          return "color: rgba(134, 4, 4, 100) ";
        } else if (row.billResultName == "无差异") {
          return "color: rgba(16, 16, 16, 100) ";
        }
      }
      if (column.label == "反馈状态") {
        if (row.status == "待处理") {
          return "color: red";
        } else if (row.status == "处理中") {
          return "color: rgb(254, 136, 0)";
        } else if (row.status == "已处理") {
          return "color:  rgb(29, 142, 51)";
        }
      }
      if (column.label == "出入库数量") {
        if (row.recordType == 1) {
          return "color: red";
        }
        if (row.recordType == 2) {
          return "color: #67C239";
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {},
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.elTable.clearSelection();
      }
    },
  },
};
</script>
<style>
.el-tooltip__popper {
  font-size: 14px;
  max-width: 25%;
}

.danger-row {
  color: rgba(134, 4, 4, 100);
}

.warning-row {
  color: rgba(255, 136, 3, 100);
}

.success-row {
  color: green;
}
</style>
<style scoped lang="scss">
.title {
  height: 100%;
}

.pageBox {
  float: right;
  margin-top: 10px;
}

::v-deep .el-table th.el-table__cell > .cell {
  color: rgba(16, 16, 16, 100);
  font-size: 14px !important;
}

::v-deep .el-table__fixed-right {
  height: 100% !important; //设置高优先，以覆盖内联样式
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}

.sign {
  ::v-deep .el-input__inner {
    border: none;
  }
}

.text {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background: red;
}

.images {
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #c0c4cc;
  font-size: 12px;

  ::v-deep .image-slot {
    background: #f5f7fa;
  }
}
</style>
